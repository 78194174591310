import React, { useState } from 'react';
import './PricingYourHome.css';
import StickyBottomBar from './StickyBottomBar';

const WhatsYourHomeWorthInTodaysMarket = () => {
  const [searchParams, setSearchParams] = useState({
    minPrice: '',
    maxPrice: '',
    search: ''
  });

  const cities = [
    'Toronto', 'Mississauga', 'Brampton', 'Oakville', 'Hamilton', 
    'Vaughan', 'Waterloo', 'London', 'Markham', 'Ajax', 'Aurora', 
    'Barrie', 'Brock', 'Burlington', 'Caledon', 'Cambridge', 
    'Clarington', 'Collingwood', 'Erin', 'Georgina', 'Grimsby', 
    'Innisfil', 'King', 'Kingston', 'Lincoln', 'Midland', 'Ottawa', 
    'Milton', 'Newmarket', 'Niagara-on-the-lake', 'Orangeville', 
    'Orillia', 'Oshawa', 'Peterborough', 'Pickering', 'Tiny', 
    'Welland', 'Whitby', 'Whitchurch'
  ];

  const styles = {
    container: {
      maxWidth: '1500px',
      margin: '0 auto',
      padding: '0rem 0rem',
    },
    grid: {
      gap: '2rem',
    },
    mainContent: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    card: {
      background: 'white',
      borderRadius: '8px',
      padding: '1rem',
      marginBottom: '0rem',
    },
    sidebarCard: {
      background: '#f3f4f6',
      borderRadius: '8px',
      padding: '1.5rem',
      marginBottom: '1rem',
    },
    sidebar: {
      position: 'sticky',
      top: '1rem',
      marginTop: '2rem',
      width: '300px',
      minWidth: '300px'
    },
    list: {
      listStyleType: 'none',
      marginBottom: '1rem',
      paddingLeft: '0',
      fontSize: '1rem',
      lineHeight: '1.5', 
    },
    priceInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      marginBottom: '0.5rem',
    },
    priceInput: {
      width: '100%',
      padding: '0.75rem',
      borderRadius: '0.25rem',
      border: '1px solid #e2e8f0',
      backgroundColor: 'white',
      fontSize: '1rem',
    },
    searchInput: {
      width: '100%',
      padding: '0.75rem',
      marginBottom: '0rem',
      borderRadius: '0.25rem',
      border: '1px solid #e2e8f0',
      backgroundColor: 'white',
      fontSize: '1rem',
    },
    searchButton: {
      display: 'inline-block',
      backgroundColor: '#2563eb',
      color: 'white',
      padding: '0.75rem 1.5rem',
      borderRadius: '0.5rem',
      textDecoration: 'none',
      cursor: 'pointer',
      border: 'none',
      width: '100%',
      fontSize: '1.25rem',
      fontWeight: '500',
    },
    button: {
      display: 'inline-block',
      backgroundColor: '#2563eb',
      color: 'white',
      padding: '0.75rem 1.5rem',
      borderRadius: '0.5rem',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '1.25rem',
      fontWeight: '500',
    },
    cityList: {
      marginTop: '1rem',
    },
    cityLink: {
      display: 'block',
      padding: '0.5rem',
      color: '#2563eb',
      textDecoration: 'none',
      fontSize: '1.25rem',
      borderRadius: '0.25rem',
      '&:hover': {
        backgroundColor: '#f0f9ff',
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const queryString = new URLSearchParams({
      city: 'All',
      community: 'null',
      minPrice: searchParams.minPrice || '0',
      maxPrice: searchParams.maxPrice || '10000000',
      bedrooms: 'Any',
      bathrooms: 'Any',
      search: searchParams.search || '',
      page: '1'
    }).toString();
    window.location.href = `/listings?${queryString}`;
  };

  const handleInputChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="pricing-container" style={styles.container}>
      <div className="pricing-grid" style={styles.grid}>
        <div className="pricing-main-content" style={styles.mainContent}>
          <section className="pricing-hero-card" style={styles.card}>
            <h1 className="pricing-title" style={styles.title}>What’s Your Home Worth in Today’s Market?</h1>
            <p className="pricing-text" style={styles.paragraph}>
              If you’re thinking about selling your home in Southern Ontario, knowing its true value is the first step—and in today’s fast-paced market, accuracy matters more than ever.
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              With the Modern Solution Team, you’ll get the most reliable and comprehensive home evaluation available. And the best part? It’s completely free and comes with no obligation.
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              Don’t rely solely on online tools or guesswork. Get the insights you need to sell your home fast and for top dollar by working with local experts who know your market better than anyone.
            </p>
          </section>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle" style={styles.subtitle}>Why Choose the Modern Solution Team?</h2>
            <p className="pricing-text" style={styles.paragraph}><b>Instant Online Evaluation:</b></p>
            <p className="pricing-text" style={styles.paragraph}>
              A quick, no-obligation estimate to give you an idea of what your home could be worth. Perfect if you’re curious or just starting to plan.
            </p>
            <p className="pricing-text" style={styles.paragraph}><b>In-Person Expert Assessment:</b></p>
            <p className="pricing-text" style={styles.paragraph}>
              Online tools can only guess—but our local, full-time REALTORS® will provide a personalized evaluation of your home’s true value.
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              We don’t just rely on algorithms—we factor in your upgrades, maintenance, curb appeal, and neighborhood trends.
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              We know what buyers are looking for in Southern Ontario homes, and we’ll show you how to maximize your value before listing.
            </p>
            <p className="pricing-text" style={styles.paragraph}><b>Market Expertise You Can Trust:</b></p>
            <p className="pricing-text" style={styles.paragraph}>
              With decades of experience in Southern Ontario, our team knows the ins and outs of every neighborhood, from city streets to suburban pockets. Our advice is tailored to your property, your location, and today’s real estate trends.
            </p>
          </div>

          <div className="pricing-cta-card" style={{...styles.card, textAlign: 'center'}}>
            <p className="pricing-text" style={styles.paragraph}>
              Don’t wait—discover your home’s true potential with no pressure and no strings attached. Let the Modern Solution Team help you sell with confidence and achieve the results you deserve.
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              Ready to learn what your home is worth?
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              ✅ <a href="/contact">For an Instant Online Evaluation: Click Here.</a>
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              ✅ <a href="/contact">For a Free Expert Assessment: Fill out the form below, and one of our local, full-time agents will contact you directly.</a>
            <p className="pricing-text" style={styles.paragraph}>
              Don’t wait—discover your home’s true potential with no pressure and no strings attached. Let the Modern Solution Team help you sell with confidence and achieve the results you deserve.
            </p>
            </p>
          </div>
        </div>

        <div className="pricing-sidebar" style={styles.sidebar}>
          <div className="pricing-search-card" style={styles.sidebarCard}>
            <h3 className="pricing-sidebar-title" style={styles.subtitle}>Search Listings</h3>
            <form onSubmit={handleSearch}>
              <div className="pricing-price-inputs" style={styles.priceInputContainer}>
                <div>
                  <input
                    type="number"
                    name="minPrice"
                    placeholder="Min Price"
                    className="pricing-input"
                    style={styles.priceInput}
                    value={searchParams.minPrice}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    name="maxPrice"
                    placeholder="Max Price"
                    className="pricing-input"
                    style={styles.priceInput}
                    value={searchParams.maxPrice}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <input
                type="text"
                name="search"
                placeholder="Search by Address"
                className="pricing-search-input"
                style={styles.searchInput}
                value={searchParams.search}
                onChange={handleInputChange}
              />
              <button type="submit" className="pricing-search-button">
                Search
              </button>
            </form>
          </div>

          <div className="pricing-cities-card" style={styles.sidebarCard}>
            <h3 className="pricing-sidebar-title" style={styles.subtitle}>Search by City</h3>
            <div className="city-list" style={styles.cityList}>
            {cities.map((city) => (
                <a
                  key={city}
                  href={`/listings?city=${city}`}
                  className="city-link"
                  style={styles.cityLink}
                >
                  Homes for sale in {city}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <StickyBottomBar />
    </div>
  );
};

export default WhatsYourHomeWorthInTodaysMarket;
