import React, { useState } from 'react';
import './PricingYourHome.css';
import StickyBottomBar from './StickyBottomBar';

const PreparingYourHouseForSale = () => {
  const [searchParams, setSearchParams] = useState({
    minPrice: '',
    maxPrice: '',
    search: ''
  });

  const cities = [
    'Toronto', 'Mississauga', 'Brampton', 'Oakville', 'Hamilton', 
    'Vaughan', 'Waterloo', 'London', 'Markham', 'Ajax', 'Aurora', 
    'Barrie', 'Brock', 'Burlington', 'Caledon', 'Cambridge', 
    'Clarington', 'Collingwood', 'Erin', 'Georgina', 'Grimsby', 
    'Innisfil', 'King', 'Kingston', 'Lincoln', 'Midland', 'Ottawa', 
    'Milton', 'Newmarket', 'Niagara-on-the-lake', 'Orangeville', 
    'Orillia', 'Oshawa', 'Peterborough', 'Pickering', 'Tiny', 
    'Welland', 'Whitby', 'Whitchurch'
  ];

  const styles = {
    container: {
      maxWidth: '1500px',
      margin: '0 auto',
      padding: '0rem 0rem',
    },
    grid: {
      gap: '2rem',
    },
    mainContent: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    card: {
      background: 'white',
      borderRadius: '8px',
      padding: '1rem',
      marginBottom: '0rem',
    },
    sidebarCard: {
      background: '#f3f4f6',
      borderRadius: '8px',
      padding: '1.5rem',
      marginBottom: '1rem',
    },
    sidebar: {
      position: 'sticky',
      top: '1rem',
      marginTop: '2rem',
      width: '300px',
      minWidth: '300px'
    },
    list: {
      listStyleType: 'none',
      marginBottom: '1rem',
      paddingLeft: '0',
      fontSize: '1.25rem',
      lineHeight: '2', 
    },
    priceInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      marginBottom: '0.5rem',
    },
    priceInput: {
      width: '100%',
      padding: '0.75rem',
      borderRadius: '0.25rem',
      border: '1px solid #e2e8f0',
      backgroundColor: 'white',
      fontSize: '1rem',
    },
    searchInput: {
      width: '100%',
      padding: '0.75rem',
      marginBottom: '0rem',
      borderRadius: '0.25rem',
      border: '1px solid #e2e8f0',
      backgroundColor: 'white',
      fontSize: '1rem',
    },
    searchButton: {
      display: 'inline-block',
      backgroundColor: '#2563eb',
      color: 'white',
      padding: '0.75rem 1.5rem',
      borderRadius: '0.5rem',
      textDecoration: 'none',
      cursor: 'pointer',
      border: 'none',
      width: '100%',
      fontSize: '1.25rem',
      fontWeight: '500',
    },
    button: {
      display: 'inline-block',
      backgroundColor: '#2563eb',
      color: 'white',
      padding: '0.75rem 1.5rem',
      borderRadius: '0.5rem',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '1.25rem',
      fontWeight: '500',
    },
    cityList: {
      marginTop: '1rem',
    },
    cityLink: {
      display: 'block',
      padding: '0.5rem',
      color: '#2563eb',
      textDecoration: 'none',
      fontSize: '1.25rem',
      borderRadius: '0.25rem',
      transition: 'background-color 0.3s',
    },
    title: {
      fontSize: '2rem',
      marginBottom: '1rem',
    },
    subtitle: {
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
    paragraph: {
      marginBottom: '1rem',
      fontSize: '1.25rem',
      lineHeight: '1.6',
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const queryString = new URLSearchParams({
      city: 'All',
      community: 'null',
      minPrice: searchParams.minPrice || '0',
      maxPrice: searchParams.maxPrice || '10000000',
      bedrooms: 'Any',
      bathrooms: 'Any',
      search: searchParams.search || '',
      page: '1'
    }).toString();
    window.location.href = `/listings?${queryString}`;
  };

  const handleInputChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="pricing-container" style={styles.container}>
      <div className="pricing-grid" style={styles.grid}>
        <div className="pricing-main-content" style={styles.mainContent}>
          <section className="pricing-hero-card" style={styles.card}>
            <h1 className="pricing-title" style={styles.title}>Preparing Your House For Sale</h1>

            {/* Section 1: Declutter and Deep Clean */}
            <section>
              <h2 style={styles.subtitle}>1. Declutter and Deep Clean</h2>
              
              <h3 style={{...styles.subtitle, fontSize: '1.25rem'}}>Declutter:</h3>
              <ul style={styles.list}>
                <li>• Remove personal items such as family photos, certificates, or unique decor to create a neutral canvas that allows buyers to envision themselves in the space.</li>
                <li>• Organize closets, cabinets, and storage spaces since buyers often look there during showings. A neat, spacious appearance signals ample storage space.</li>
              </ul>
              
              <h3 style={{...styles.subtitle, fontSize: '1.25rem'}}>Deep Clean:</h3>
              <ul style={styles.list}>
                <li>• Clean every nook and cranny of your home, including carpets, tiles, windows, and light fixtures.</li>
                <li>• Eliminate dust and grime from baseboards, vents, and hard-to-reach areas.</li>
                <li>• Pay special attention to kitchens and bathrooms—they should sparkle.</li>
              </ul>
            </section>

            {/* Section 2: Address Repairs and Maintenance */}
            <section>
              <h2 style={styles.subtitle}>2. Address Repairs and Maintenance</h2>
              <ul style={styles.list}>
                <li>• Fix visible issues such as leaky faucets, running toilets, squeaky doors, and chipped paint.</li>
                <li>• Replace cracked tiles, damaged grout, and broken fixtures.</li>
                <li>• Ensure that HVAC systems, appliances, and smoke detectors are in working order.</li>
                <li>• If there are significant issues like roof leaks or foundation problems, consider addressing them beforehand to avoid negotiation setbacks.</li>
              </ul>
            </section>

            {/* Section 3: Enhance Curb Appeal */}
            <section>
              <h2 style={styles.subtitle}>3. Enhance Curb Appeal</h2>
              <p style={styles.paragraph}>
                The exterior of your home creates the first impression. Make it count!
              </p>
              <ul style={styles.list}>
                <li>• Mow the lawn, trim bushes, and plant fresh flowers or greenery.</li>
                <li>• Power-wash the driveway, sidewalks, and siding to remove dirt and stains.</li>
                <li>• Repaint or touch up your front door, shutters, and trim for a refreshed look.</li>
                <li>• Ensure outdoor lighting is functional and appealing, especially for evening showings.</li>
                <li>• Place a clean, inviting doormat and consider adding potted plants near the entrance.</li>
              </ul>
            </section>

            {/* Section 4: Neutralize and Depersonalize */}
            <section>
              <h2 style={styles.subtitle}>4. Neutralize and Depersonalize</h2>
              <ul style={styles.list}>
                <li>• Paint walls in soft, neutral colors like beige, gray, or off-white to create a blank slate.</li>
                <li>• Remove bold or unique design elements that may not suit every buyer’s taste.</li>
                <li>• Neutral decor makes it easier for buyers to imagine their own furnishings in the space.</li>
              </ul>
            </section>

            {/* Section 5: Stage Your Home */}
            <section>
              <h2 style={styles.subtitle}>5. Stage Your Home</h2>
              <p style={styles.paragraph}>
                Staging helps highlight your home’s best features and makes it more attractive to potential buyers.
              </p>
              <ul style={styles.list}>
                <li>• Rearrange furniture to create open, functional spaces with good flow.</li>
                <li>• Use tasteful, neutral decor like throw pillows, rugs, and artwork.</li>
                <li>• Highlight special features such as a fireplace, large windows, or built-in shelves by drawing attention to them with lighting or placement.</li>
                <li>• In outdoor spaces, add cozy touches like a small bistro set or comfortable seating to showcase how the space can be used.</li>
              </ul>
            </section>

            {/* Section 6: Lighting and Ambiance */}
            <section>
              <h2 style={styles.subtitle}>6. Lighting and Ambiance</h2>
              <ul style={styles.list}>
                <li>• Maximize natural light by opening curtains and blinds.</li>
                <li>• Replace dim or outdated lightbulbs with bright, energy-efficient ones.</li>
                <li>• Add lamps or other light sources to dark corners to brighten every space.</li>
                <li>• Create a warm ambiance by turning on lights throughout the house during showings.</li>
              </ul>
            </section>

            {/* Section 7: Focus on Kitchens and Bathrooms */}
            <section>
              <h2 style={styles.subtitle}>7. Focus on Kitchens and Bathrooms</h2>
              <p style={styles.paragraph}>
                Buyers often pay close attention to these spaces:
              </p>
              <h3 style={{...styles.subtitle, fontSize: '1.25rem'}}>Kitchen:</h3>
              <ul style={styles.list}>
                <li>• Clear counters of clutter and leave only a few decorative items, like a bowl of fruit or a vase of flowers.</li>
                <li>• Clean or replace appliances, if necessary, to make them look well-maintained.</li>
                <li>• Organize cabinets and drawers since buyers often peek inside.</li>
              </ul>
              <h3 style={{...styles.subtitle, fontSize: '1.25rem'}}>Bathrooms:</h3>
              <ul style={styles.list}>
                <li>• Replace old shower curtains, mats, and towels with fresh, neutral ones.</li>
                <li>• Clean mirrors, sinks, and tubs thoroughly.</li>
                <li>• Store personal hygiene items out of sight to keep countertops clear.</li>
              </ul>
            </section>

            {/* Section 8: Highlight Outdoor Living Spaces */}
            <section>
              <h2 style={styles.subtitle}>8. Highlight Outdoor Living Spaces</h2>
              <p style={styles.paragraph}>
                If your home has a patio, balcony, or backyard, make sure these areas are appealing:
              </p>
              <ul style={styles.list}>
                <li>• Clean outdoor furniture and arrange it attractively.</li>
                <li>• Add cushions or an outdoor rug to create a cozy atmosphere.</li>
                <li>• Ensure the landscaping is neat and inviting.</li>
              </ul>
            </section>

            {/* Section 9: Final Touches Before Showings */}
            <section>
              <h2 style={styles.subtitle}>9. Final Touches Before Showings</h2>
              <ul style={styles.list}>
                <li>• Keep your home smelling fresh by using subtle air fresheners or natural scents like freshly baked cookies or brewed coffee. Avoid overpowering scents.</li>
                <li>• Add a few touches of life, like fresh flowers or a fruit bowl, to make your home feel warm and inviting.</li>
                <li>• Remove pets and their belongings during showings to avoid distractions.</li>
                <li>• Ensure the home is spotless at all times, as buyers may schedule last-minute visits.</li>
              </ul>
            </section>

            {/* Section 10: Price It Right */}
            <section>
              <h2 style={styles.subtitle}>10. Price It Right</h2>
              <ul style={styles.list}>
                <li>• Consult with your real estate agent to determine a competitive and realistic listing price. Consider the condition of your home, its location, and comparable properties in the area. Pricing your home correctly will attract more buyers and lead to faster offers.</li>
              </ul>
              <p style={styles.paragraph}>
                By following these steps, you’ll make your home as appealing as possible to potential buyers. If you need expert advice or assistance, Modern Solution Realty is here to help you with a seamless selling experience while saving you money with our 1% listing fee!
              </p>
            </section>
          </section>

          <div className="pricing-content-card" style={styles.card}>
            {/* You can add additional content here if needed */}
          </div>

          <div className="pricing-cta-card" style={{...styles.card, textAlign: 'center'}}>
            <p className="pricing-text" style={styles.paragraph}>
              Don’t wait—discover your home’s true potential with no pressure and no strings attached. Let the Modern Solution Team help you sell with confidence and achieve the results you deserve.
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              Ready to learn what your home is worth?
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              ✅ <a href="/contact">For an Instant Online Evaluation: Click Here.</a>
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              ✅ <a href="/contact">For a Free Expert Assessment: Fill out the form below, and one of our local, full-time agents will contact you directly.</a>
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              Don’t wait—discover your home’s true potential with no pressure and no strings attached. Let the Modern Solution Team help you sell with confidence and achieve the results you deserve.
            </p>
          </div>
        </div>

        <div className="pricing-sidebar" style={styles.sidebar}>
          <div className="pricing-search-card" style={styles.sidebarCard}>
            <h3 className="pricing-sidebar-title" style={styles.subtitle}>Search Listings</h3>
            <form onSubmit={handleSearch}>
              <div className="pricing-price-inputs" style={styles.priceInputContainer}>
                <div>
                  <input
                    type="number"
                    name="minPrice"
                    placeholder="Min Price"
                    className="pricing-input"
                    style={styles.priceInput}
                    value={searchParams.minPrice}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    name="maxPrice"
                    placeholder="Max Price"
                    className="pricing-input"
                    style={styles.priceInput}
                    value={searchParams.maxPrice}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <input
                type="text"
                name="search"
                placeholder="Search by Address"
                className="pricing-search-input"
                style={styles.searchInput}
                value={searchParams.search}
                onChange={handleInputChange}
              />
              <button type="submit" className="pricing-search-button" style={styles.searchButton}>
                Search
              </button>
            </form>
          </div>

          <div className="pricing-cities-card" style={styles.sidebarCard}>
            <h3 className="pricing-sidebar-title" style={styles.subtitle}>Search by City</h3>
            <div className="city-list" style={styles.cityList}>
            {cities.map((city) => (
                <a
                  key={city}
                  href={`/listings?city=${city}`}
                  className="city-link"
                  style={styles.cityLink}
                >
                  Homes for sale in {city}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <StickyBottomBar />
    </div>
  );
};

export default PreparingYourHouseForSale;
